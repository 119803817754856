<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card elevation="0">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
              to="/"
              class="d-flex align-center"
          >
            <v-img
                :src="require('@/assets/images/logos/logo.png')"
                height="auto"
                max-width="100px"
                alt="logo"
                contain
                class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="h2 text-center">
            {{ $t('login.welcome') }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-alert
              v-show="alert.active"
              dismissible
              :color="alert.color"
              border="left"
              elevation="2"
              colored-border
              :icon="alert.icons"
          >
            {{alert.text}}
          </v-alert>
          <v-form @submit.prevent="login">
            <v-text-field
                v-model="user.login"
                outlined
                :label="$t('login.email')"
                placeholder="john@example.com"
                hide-details
                class="mb-3"
            ></v-text-field>

            <v-text-field
                v-model="user.password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                :label="$t('login.password')"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                  v-model="user.remenber"
                  :label="$t('login.remenber')"
                  hide-details
                  class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                  href="javascript:void(0)"
                  class="mt-1"
              >
                {{ $t('login.forgot_password') }}
              </a>
            </div>

            <v-btn
                block
                :loading="loading"
                :disabled="loading"
                type="submit"
                @click="loader = 'loading'"
                small
                class="mt-4"
                color="primary"
            >
              <p class="h2 text-center text-capitalize pt-4">
                <v-icon style="color: white" size="20">{{ icons.mdiAccountCircle }} </v-icon>
                {{ $t('login.login') }}
              </p>
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap">
          <span class="me-2 h4">
            {{$t('login.new_our_platform')}}?
          </span>
          <router-link :to="{name:'auth-register'}">
            <span class="h4">{{ $t('login.create_account') }}
          </span>
          </router-link>
        </v-card-text>

        <!-- divider
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">
            {{ $t('login.or') }}</span>
          <v-divider></v-divider>
        </v-card-text>

        social links
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>  -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
        class="auth-mask-bg"
        height="173"
        :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountCircle, mdiEyeOutline, mdiEyeOffOutline, mdiAlertRemove, mdiThumbUp } from '@mdi/js'

export default {
  data: () => ({
    user: {
      login: undefined,
      password: undefined,
      remenber:false
    },
    alert:{
      color: '',
      text:'',
      icons: mdiEyeOutline,
      active: false
    },
    isPasswordVisible:false,
    loader: null,
    loading: false,
    icons: {
      mdiEyeOutline,
      mdiAccountCircle,
      mdiEyeOffOutline,
      mdiAlertRemove,
      mdiThumbUp
    },
  }),
  methods: {
    login: function () {
      const l = this.loader
      this[l] = !this[l]
      let bodyFormData = new FormData();
      bodyFormData.append("login", this.user.login);
      bodyFormData.append("password", this.user.password);
      const promise = this.$store.dispatch('login', bodyFormData)
      promise.then((res) => {
        console.log(res)
        this.alert = {
          color: "success",
          active: true,
          text: "connexion reussie!",
          icons: this.icons.mdiThumbUp
        }
        this.inprocess = false;
        !this.$store.getters.isLoggedIn ? this.$router.push('/auth/activation') : this.$router.push('/dashboard');

        this[l] = false
        this.loader = null
      })
          .catch(err => {
            this.alert = {
              color: "error",
              active: true,
              text: err.login,
              icons: this.icons.mdiAlertRemove
            }
            console.log(err)
            this[l] = false
            this.loader = null
          })
    }
  },
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
